import { render, staticRenderFns } from "./Role.vue?vue&type=template&id=a1115c2c&scoped=true&"
import script from "@/views/Settings/Roles/Role.ts?vue&type=script&lang=ts&"
export * from "@/views/Settings/Roles/Role.ts?vue&type=script&lang=ts&"
import style0 from "@/views/Settings/Roles/Role.scss?vue&type=style&index=0&id=a1115c2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1115c2c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a1115c2c')) {
      api.createRecord('a1115c2c', component.options)
    } else {
      api.reload('a1115c2c', component.options)
    }
    module.hot.accept("./Role.vue?vue&type=template&id=a1115c2c&scoped=true&", function () {
      api.rerender('a1115c2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Settings/Roles/Role.vue"
export default component.exports