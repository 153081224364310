var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      _vm._b(
        { staticClass: "atabix-permission-selector" },
        "div",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.hideTopSeparator
        ? _c("div", { staticClass: "atabix-permission-selector__seperator" })
        : _vm._e(),
      _vm.hasTitle
        ? _c("div", { staticClass: "atabix-permission-selector__container" }, [
            _c(
              "div",
              {
                staticClass: "atabix-permission-selector__title",
                on: { click: _vm.toggleAllPermissions }
              },
              [
                _c(
                  "v-icon",
                  { staticClass: "atabix-permission-selector__toggle-all" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.allPermissionsSelected
                            ? "mdi-checkbox-multiple-marked"
                            : "mdi-checkbox-multiple-blank-outline"
                        ) +
                        " "
                    )
                  ]
                ),
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "atabix-permission-selector__seperator" }),
      _c(
        "div",
        { staticClass: "atabix-permission-selector__content" },
        [
          _c(
            "v-layout",
            { staticClass: "pb-16", attrs: { wrap: "" } },
            [
              _vm.internalPermissions.length
                ? [
                    _vm._l(_vm.internalPermissions, function(permission) {
                      return [
                        _c(
                          "v-flex",
                          { key: permission.id, attrs: { xs12: "", md4: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                "hide-details": "",
                                name: "useAllowance",
                                color: "#a09b1b"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("div", { staticClass: "ml-8" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "subheading font-weight-bold ma-0",
                                              class: _vm.checkboxLabelClasses(
                                                permission
                                              )
                                            },
                                            [_vm._v(_vm._s(permission.name))]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "caption mb-0" },
                                            [
                                              _vm._v(
                                                _vm._s(permission.description)
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: permission.value,
                                callback: function($$v) {
                                  _vm.$set(permission, "value", $$v)
                                },
                                expression: "permission.value"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }