var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "grid-list-lg": "" } },
        [
          _vm.isLoading
            ? _c("LoaderCard", { attrs: { flat: "", type: "spinner--center" } })
            : _vm._e(),
          !_vm.isLoading && _vm.role
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { sm12: "", md5: "", lg4: "", xl3: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "elementPanel profile" },
                        [
                          !_vm.isLoading
                            ? [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "profileContainer",
                                    class: { editing: _vm.isEditingRole },
                                    attrs: { row: "", wrap: "" }
                                  },
                                  [
                                    !_vm.isEditingRole
                                      ? _c(
                                          "div",
                                          { staticClass: "iconContainer" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "iconContainer__edit"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "edit-icon",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.isEditingRole = true
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("edit")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isEditingRole
                                      ? _c(
                                          "div",
                                          { staticClass: "iconContainer" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "cancel-icon",
                                                on: {
                                                  click: function($event) {
                                                    _vm.isEditingRole = false
                                                    _vm.fetchRole()
                                                  }
                                                }
                                              },
                                              [_vm._v(" cancel ")]
                                            ),
                                            !_vm.isUpdating
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "save-icon",
                                                    on: { click: _vm.update }
                                                  },
                                                  [_vm._v("save")]
                                                )
                                              : _vm._e(),
                                            _vm.isUpdating
                                              ? _c("LoaderCard", {
                                                  staticClass:
                                                    "spinner--inline",
                                                  attrs: {
                                                    type: "spinner--small"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.role
                                      ? _c(
                                          "v-flex",
                                          { attrs: { "mt-3": "" } },
                                          [
                                            _vm.isEditingRole
                                              ? [
                                                  _c("v-text-field", {
                                                    attrs: { label: "Naam" },
                                                    model: {
                                                      value: _vm.role.name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.role,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "role.name"
                                                    }
                                                  })
                                                ]
                                              : _vm._e(),
                                            !_vm.isEditingRole
                                              ? [
                                                  _c(
                                                    "h1",
                                                    {
                                                      staticClass:
                                                        "profile__userName"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-light"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.role.name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { sm12: "", md7: "", lg8: "", xl9: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: { position: "relative" },
                              attrs: { "pb-0": "" }
                            },
                            [
                              _c(
                                "v-tabs",
                                {
                                  attrs: { "slider-color": "secondary" },
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab"
                                  }
                                },
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab__filled",
                                      class: {
                                        active:
                                          _vm.activeTab === "tab-permissions"
                                      },
                                      attrs: { href: "#tab-permissions" }
                                    },
                                    [_vm._v(" Permissies ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab"
                                  }
                                },
                                [
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        transition: false,
                                        "reverse-transition": false,
                                        lazy: true,
                                        value: "tab-permissions"
                                      }
                                    },
                                    [
                                      _vm.activeTab === "tab-permissions"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementPanel elementPanel--user"
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { "text-right": "" } },
                                                [
                                                  _c(
                                                    "MiButton",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        disabled:
                                                          _vm.isSavingPermissions,
                                                        icon: "save",
                                                        round: "true",
                                                        outline: "true",
                                                        background: "true"
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.savePermissions(
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Opslaan ")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.permissions && !_vm.isLoading
                                                ? [
                                                    _vm._l(
                                                      Object.keys(
                                                        _vm.permissions
                                                      ),
                                                      function(
                                                        category,
                                                        index
                                                      ) {
                                                        return [
                                                          _c(
                                                            "PermissionSelector",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                hideTopSeparator:
                                                                  index === 0,
                                                                permissions:
                                                                  _vm
                                                                    .permissions[
                                                                    category
                                                                  ],
                                                                title: category,
                                                                role: _vm.role
                                                              },
                                                              on: {
                                                                "update:permissions": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.permissions,
                                                                    category,
                                                                    $event
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ]
                                                      }
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }