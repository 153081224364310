import { Component, Vue } from 'vue-property-decorator';
import { groupBy, Dictionary, flatMap } from 'lodash';
import { Permission } from '@/models/Permission';
import { PermissionSlug } from '@/support/PermissionSlug';
import { Role as RoleModel } from '@/models/Role';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import PermissionSelector from '@/components/PermissionSelector/PermissionSelector.vue';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<Role>({
  components: {
    PermissionSelector,
  },
})

export default class Role extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected isLoading = false;

  protected isUpdating = false;

  protected isEditingRole = false;

  protected isSavingPermissions = false;

  protected role: RoleModel | null = null;

  protected activeTab = 'tab-permissions';

  protected permissions: Dictionary<Permission[]> | null = null;
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected async mounted() {
    this.isLoading = true;
    await this.fetchRole();
    await this.fetchPermissions();
    this.emitBreadcrumb();
    this.isLoading = false;
  }
  // #endregion

  // #region Class methods

  protected getSelectedPermissionSlugs(): PermissionSlug[] {
    if (! this.permissions) {
      return [];
    }
    const permissionCollection: PermissionSlug[] = [];
    flatMap(this.permissions).forEach((permission) => {
      if (permission.value) {
        permissionCollection.push(permission.slug);
      }
    });

    return permissionCollection;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rollen', path: '/instellingen/rollen' },
          { name: this.role?.name || '' },
        ],
      });
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  protected async fetchRole(): Promise<void> {
    const role = await new RoleModel()
      .include(['permissions'])
      .find(this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (role) {
      this.role = role;
    }
  }

  protected async fetchPermissions(): Promise<void> {
    const permissions = await new Permission()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (permissions) {
      this.permissions = groupBy(permissions, 'category');
    }
  }

  protected async update(): Promise<void> {
    if (! this.role) {
      return;
    }

    this.isUpdating = true;

    const role = await this.role
      .include(['permissions'])
      .update({ name: this.role.name })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (role) {
      this.role = role;
      this.isUpdating = false;
      this.isEditingRole = false;
    }
  }

  protected async savePermissions(): Promise<void> {
    if (! this.role) {
      return;
    }
    this.isSavingPermissions = true;

    const role = await this.role
      .include(['permissions'])
      .update(this.permissionPayload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (role) {
      this.role.permissions = role.permissions;
      this.$store.dispatch('openDialog', this.dialogOptionsSuccessPermissionsAdded);
    }

    this.isSavingPermissions = false;
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get permissionPayload() {
    return {
      permissions: this.getSelectedPermissionSlugs(),
    };
  }

  private get dialogOptionsSuccessPermissionsAdded(): Options {
    return {
      title: 'Gelukt!',
      text: 'Permissies zijn successvol toegevoegd aan de rol.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
