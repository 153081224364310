import { Permission } from '@/models/Permission';
import { Role } from '@/models/Role';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component<PermissionSelector>({})
export default class PermissionSelector extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @PropSync('permissions')
  protected internalPermissions!: Permission[];

  @Prop({ default: '' })
  protected title!: string;

  @Prop()
  protected role!: Role;

  @Prop({ default: false })
  protected hideTopSeparator!: boolean;

  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected isLoading = false;

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
*/

  protected mounted(): void {
    this.initialize();
  }

  // #endregion

  // #region Class methods
  // #endregion
  protected async initialize(): Promise<void> {
    this.isLoading = true;
    this.createModifiedPermissions();
    this.isLoading = false;
  }

  protected createModifiedPermissions(): void {
    this.internalPermissions.forEach((permission) => {
      this.$set(permission, 'value', this.role?.permissions.some((rolePermission) => rolePermission.id === permission.id));
    });
  }

  protected toggleAllPermissions(): void {
    const value = ! this.allPermissionsSelected;
    this.internalPermissions.forEach((permission) => {
      this.$set(permission, 'value', value);
    });
  }

  protected checkboxLabelClasses(permission: Permission): string {
    return permission.value ? 'font-semibold text-primary-500' : 'font-normal text-black-400';
  }

  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get hasTitle(): boolean {
    return !! this.title
      || !! this.$slots?.title?.find((slotInput) => !! slotInput.text || !! slotInput.data); // Slot was not filled by parent
  }

  protected get allPermissionsSelected(): boolean {
    return this.internalPermissions.every((permission) => permission.value);
  }

  protected get toggleText(): string {
    return this.allPermissionsSelected ? 'deselecteren' : 'selecteren';
  }

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
